import { EnglishVerb } from '../../classes';
import * as VerbActions from './verbs.actions';
import * as VerbSelectors from './verbs.selectors';

export { VerbActions, VerbSelectors };

export interface VerbsState {
  verbs: ReadonlyArray<EnglishVerb>;
  readonly isLoaded: boolean;
  readonly isLoading: boolean;
  readonly isRehydrated: boolean;
  readonly error: string;
}
