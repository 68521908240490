import { Routes } from '@angular/router';

import { AuthGuardService } from './core/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./information/information-routing').then((m) => m.RoutesInfo),
    // redirectTo: 'auth',
    // pathMatch: 'full',
  },

  {
    path: 'm',
    loadComponent: () => import('./layout-ionic/layout-ionic.component').then((m) => m.IonicLayoutComponent),
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),

    // children: [{ path: 'home', loadComponent: () => import('./pages/dashboard/dashboard.component').then((m) => m.DashboardComponent) }],
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
    canActivate: [AuthGuardService],
  },

  {
    path: '',
    loadChildren: () => import('./layout/layout-routing').then((m) => m.RoutesLayout),
    canActivate: [AuthGuardService],
  },
];
