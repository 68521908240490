export enum RouteNames {
  Home = 'dashboard',
  Mobile = 'm',
  Dashboard = 'dashboard',
  Flashcards = 'flashcards',
  Profile = 'profile',
  SubscriptionPlan = 'plan',
  Words = 'my-words',
  Phrases = 'my-phrases',
  Verbs = 'my-verbs',
  Chat = 'my-chat',
  Scenarios = 'escenarios',
  Topics = 'topics',
  Lessons = 'lecciones',
  Info = 'informacion',
  // TODO quiza debo quitar CreateLesson de aquí
  CreateLesson = 'create-lesson',
  Admin = 'admin',
  Teacher = 'teacher',
  VoiceDictation = 'dictado',
  Discovery = 'ejemplos',
  Signup = 'signup',
  Login = 'login',
}

export enum RoutesInformation {
  Main = 'landing',
  Policy = 'politicas-de-privacidad',
  Terms = 'terminos-y-condiciones',
  NotFound = 'not-found',
}

export enum TopicRoutes {
  practice = 'details',
  edit = 'edit',
}

export enum WordWebApi {
  GetUserWord = 'api/word',
  GetWord = 'api/word/db', // api/word/db/{lang}/{word}
  PostWord = 'api/word',
  SaveWordWithString = 'api/word/user', // api/word/user/{lang}/{word}
  DeleteWord = 'api/word',
  SaveProgress = 'api/word/progress',
  AutocompleteWord = 'api/word/autocomplete',
  PromoteWord = 'dictionary/promote/word',
  RandomWord = 'api/word/random',
}

export enum VerbWebApi {
  GetVerb = 'dictionary/verb',
  GetVerbs = 'api/verb',
  PostVerb = 'user/verb',
  DeleteVerb = 'api/verb/user',
  SaveProgress = 'api/verb/progress',
  saveVerbWithString = 'api/verb/user', // api/verb/user/{verb}
  saveVerbTopicWithString = 'dictionary/topic/verb',
  AutocompleteVerb = 'api/verb/autocomplete',
  PromoteVerb = 'dictionary/promote/verb',
}

export enum UserWebApi {
  User = 'api/init/user',
  UserSurvey = 'api/init/user/intro',
  UserNewsViewed = 'user/newsViewed',
  UserSteps = 'api/user/steps',
  UserPromoCode = 'api/user/promoCode',
  UserStats = 'api/user/stats',
  LessonTaken = 'api/user/lessonTaken',
  LangProgress = 'api/user/langProgress',
  ChangeLanguage = 'api/user/changeLanguage',
}

export enum TopicWebApi {
  getTopics = 'topics',
  getTopic = 'topic', // topic/{topicId}?[complete=true] : optional complete topic contains words and verbs
  postTopic = 'topic',
  getWordsOfTopic = 'topic/word', // topic/word/{topicId}
  PostWordIntoTopic = 'topic/word', // example topic/word/{topicId}
  deleteWordFromTopic = 'topic/word', // topic/word/{topicId}/{wordId}
  PostVerbIntoTopic = 'topic/verb', // example topic/word/{topicId}
  DeleteVerbFromTopic = 'topic/verb', // topic/word/{topicId}/{wordId}
}

// TODO revisar que tenga todos los servicios aquí marcados
export enum PhrasesWebApi {
  getPhrases = 'api/phrases',
  postPhrase = 'api/phrase',
  deletePhrase = 'api/phrase',
  saveProgress = 'api/progress/phrases',
  savePhraseWithString = 'dictionary/user/phrase',
  savePhraseTopicWithString = 'dictionary/topic/phrases',
  autocompletePhrase = 'dictionary/autocomplete/phrases',
  promotePhrase = 'dictionary/promote/phrases',
}

export enum AdminApi {
  PromotedWords = 'admin/words/promoted',
  PromotedVerbs = 'admin/verbs/promoted',
  MergeWord = 'admin/words/savePromoted',
  DeletePromotion = 'admin/promotion', // admin/promotion/{wordType}
  UpdateKnowledge = 'api/admin/word', // admin/knowledge/{wordType}
  TagWords = 'admin/words/tag',
  User = 'api/admin/user',
  UserClaims = 'api/admin/claims',
  DeleteUser = 'api/admin/user', // api/admin/user/{email}
  Word = 'api/admin/word', // {admin/word/{word}}
  Verb = 'admin/verb', // {admin/word/{verb}}
  PromoCode = 'api/admin/promoCode',
}

export enum PaymentApi {
  CreateCheckoutSession = 'api/payments/create-checkout-session',
  GetCheckoutSession = 'api/payments/checkout-session',
  makePaymentIntent = 'createPaymentIntent',
  ActivateSubscription = 'payments/activate',
  GetPromo = 'payments/promo',
}

export enum ReactionApi {
  post = 'api/reaction',
  get = 'api/reaction/get',
  delete = 'api/reaction/remove',
}

// TODO: this will be deprecated
export enum StudentApi {
  addStudentToTeacher = 'student/add', // student/add/{email}
  getStudents = 'student/users', // student/add/{email}
  getStudentWords = 'student/user/words', // 'student/user/words/{userId}'
  saveStudentWord = 'student/user/word', // 'student/user/words/{userId}'
  getCollection = 'student/collection', // TODO eliminar si no me sirve
  crudCollection = 'student/collection', // requiere post acepta metodos de tipo Methods
}

export enum TeacherApi {
  AddStudentToTeacher = 'teacher/addStudent', // student/addStudent/{email}
  DeleteStudentToTeacher = 'teacher/deleteStudent', // student/deleteStudent/{email}
  AddLessonStudent = 'teacher/addLessonToStudent', // student/deleteStudent/{email}
  GetStudentWords = 'teacher/student/words', // teacher/student/words/{user_id}
  TeacherAdmin = 'teacher/admin', // teacher/admin
}

export enum LessonApi {
  CreateLesson = 'api/lesson',
  DeleteLesson = 'api/lesson',
  GetLesson = 'api/lesson', // lesson/{id}
  GetLessons = 'api/lesson/query',
  GetPublicLessons = 'api/lesson/publicLessons',
  GetUnpublishedLessons = 'api/lesson/unpublished',
  TakenLesson = 'api/lesson/taken',
  GenerateMedia = 'api/lesson/generateMedia', // /{id}
  GenerateLesson = 'api/lesson/generateLesson',
  Recommendations = 'api/lesson/recommendation',
}

// TODO: Create all this in the backend.
export enum ScenarioApi {
  CreateScenario = 'api/scenario',
  DeleteScenario = 'api/scenario',
  UpdateScenario = 'api/scenario',
  GetScenario = 'api/scenario', // lesson/{id}
  GetScenarioQuery = 'api/scenario/query',
  Recommendations = 'api/scenario/recommendation',

  // GetPublicLessons = 'api/lesson/publicLessons',
  // GetUnpublishedLessons = 'api/lesson/unpublished',
  // TakenLesson = 'api/lesson/taken',
  // GenerateMedia = 'api/lesson/generateMedia', // /{id}
}

export enum UtilsAPI {
  Translation = 'api/utils/translation',
  SimpleInstruction = 'api/utils/simpleInstruction',
}

export enum AIAPI {
  GenerateExample = 'api/generativeAI/generateExample', // generativeAI/generateExample/{lang}/{word}
  GenerateExamples = 'api/generativeAI/generateExamples', // generativeAI/generateExamples/{word}
  GenerateVerbExample = 'api/generativeAI/generateVerbExample', // generativeAI/generateVerbExample
  GenerateWordAudio = 'api/generativeAI/generateAudio', // generativeAI/generateAudio/{word}
  GetTTS = 'api/generativeAI/tts', // generativeAI/tts/{text}
  AutocompleteWordLearningExample = 'api/generativeAI/autocomplete/learningExample', // generativeAI/autoGenerateLearningExample
  GenerateChat = 'api/generativeAI/chat', // generativeAI/generateVerbExample
  RealTimeConversation = 'api/generativeAI/rtconversation', // generativeAI/generateVerbExample
  Whisper = 'api/generativeAI/whisper_fal', // generativeAI/generateVerbExample
  ServerStatus = 'api/utils/status',
  ListModels = 'api/generativeAI/listModels',
  GenerateScenario = 'api/generativeAI/generateScenario',
}

export enum LearninExampleAPI {
  GetLearningExamples = 'api/learningExamples',
  GetLearningExamplesRandom = 'api/learningExamples/random', // api/learningExamples/word/{langCode}/{word}
  GetWordLearningExample = 'api/learningExamples/word', // api/learningExamples/word/{word}
  PostLearningExample = 'api/learningExamples/word', // api/learningExamples/word/{word}
  DeleteLearningExample = 'api/learningExamples/word', // api/learningExamples/word/{word}/{id}
}

export enum TeacherStudentsMethods {
  Get = 'get',
  Save = 'save',
  SaveWithString = 'saveWithString',
  Delete = 'delete',
}

export enum PermissionType {
  Vocabulary = 'vocabulary',
  LearningExampleRequest = 'leRequest',
  Conversation = 'conversation',
}

export enum RolType {
  Admin = 'admin',
  Teacher = 'teacher',
  Tester = 'tester',
}

export const PermissionsOptions = [
  { name: 'Vocabulario', value: PermissionType.Vocabulary },
  { name: 'Conversaciones', value: PermissionType.Conversation },
  { name: 'Búsquedas Ejemplos', value: PermissionType.LearningExampleRequest },
];

export const RolOptions = [
  { name: 'Administrador', value: RolType.Admin },
  { name: 'Profesor', value: RolType.Teacher },
  { name: 'Tester', value: RolType.Tester },
];

export enum WordType {
  Word = 'word',
  Verb = 'verb',
}

export enum PlanType {
  Basic = 'basic',
  Follower = 'follower',
  Beta = 'beta',
  Premium = 'premium',
}

export enum VerbTense {
  PresentSimple = 'Present Simple',
  PresentContinuous = 'Present Continuous',
  PresentPerfect = 'Present Perfect',
  PresentPerfectContinuous = ' Present Perfect Continuous',
  PastSimple = 'Past Simple',
  PastContinuous = 'Past Continuous',
  PastPerfect = 'Past Perfect',
  PastPerfectContinuous = 'Past Perfect Continuous',
  FutureSimple = 'Future Simple',
  FutureContinuous = 'Future Continuous',
  FuturePerfect = 'Future Perfect',
  FuturePerfectContinuous = 'Future Perfect Continuous',
}

// es una constante con la lista de tenses quizá deba estar en una archivo especial de const
export const Tenses = [
  VerbTense.PresentSimple,
  VerbTense.PresentContinuous,
  VerbTense.PresentPerfect,
  VerbTense.PresentPerfectContinuous,
  VerbTense.PastSimple,
  VerbTense.PastContinuous,
  VerbTense.PastPerfect,
  VerbTense.PastPerfectContinuous,
  VerbTense.FutureSimple,
  VerbTense.FutureContinuous,
  VerbTense.FuturePerfect,
  VerbTense.FuturePerfectContinuous,
];

// not and enum, but needed for catalog, what proceds here?
// Esto debería venir desde el backend ya que pobablemente cambie los nombres revisar VoiceType en el backend

// TODO que hacer con las voces

// @deprecated use VoiceOptions in constants.
//  this is types only for Google
export const VoiceTypes = [
  { value: 'Man2Studio', name: 'Hombre Studio 2' },
  { value: 'WomanStudio', name: 'Mujer Studio' },
];

export const scoreOptions = [
  { value: 0, label: 'Primera vez' },
  { value: 2, label: 'Memorizando' },
  { value: 5, label: 'Poco Frecuente' },
  { value: 7, label: 'Utilizada' },
  { value: 9, label: 'Gran Dominio' },
];

export const useFrequencyOptions = [
  { value: 1, label: 'Rara vez' },
  { value: 2, label: 'Poco Utilizado' },
  { value: 3, label: 'Normal' },
  { value: 4, label: 'Utilizado' },
  { value: 5, label: 'Muy Utilizado' },
];

export const wordTypeOptions = [
  { value: 'noun', label: 'Noun (sustantivo)' },
  { value: 'adjective', label: 'Adjective (adjetivo)' },
  { value: 'pronoun', label: 'Pronoun (pronombre)' },
  { value: 'adverb', label: 'Adverb (adverbio)' },
  { value: 'preposition', label: 'Preposition (preposición)' },
  { value: 'conjuction', label: 'Conjuction' },
  { value: 'injection', label: 'Injection' },
  { value: 'phrase', label: 'Phrase (frase)' },
  { value: 'compound_noun', label: 'Compound Noun (Pronombre compuesto)' },
];

export enum StateStep {
  Taken = 'taken',
  Skipped = 'skipped',
  Pending = 'pending',
}

export enum FrontEndStepsType {
  IntroTaken = 'introTaken',
  HomeTourState = 'homeTourState',
  VerbsTourState = 'verbsTourState',
}

export const enum WordFormMode {
  Regular = 'regular',
  Suggestion = 'suggestion',
  Admin = 'admin',
}

export enum CommentsApi {
  PostComment = 'api/comment',
  DeleteComment = 'api/comment',
  GetComments = 'api/comment', // /{id}
}

export enum BusinessRule {
  MaxWords = 35,
  MaxVerbs = 20,
}

export const ExpireDateOptions = [
  { name: 'No expira', value: null },
  { name: '1 Mes', value: 1 },
  { name: '2 Meses', value: 2 },
  { name: '3 Meses', value: 3 },
  { name: '4 Meses', value: 4 },
  { name: '6 Meses', value: 6 },
  { name: '1 Año', value: 12 },
];

export enum ActionPromoCode {
  FreePermission = 'freePermission',
  FreePlan = 'freePlan',
  PlanDiscount = 'planDiscount',
}

export const ActionPromoCodeOptions = [
  { name: 'Privilegio Gratis', value: ActionPromoCode.FreePermission },
  { name: 'Plan Gratis', value: ActionPromoCode.FreePlan },
  { name: 'Descuento en el plan', value: ActionPromoCode.PlanDiscount },
];

export const PlanOptions = [
  { name: 'Básico', value: PlanType.Basic },
  { name: 'Beta', value: PlanType.Beta },
  { name: 'Follower', value: PlanType.Follower },
  { name: 'Premium', value: PlanType.Premium },
];

export enum AppHttpCode {
  GoodRefreshToken = 211,
  ErrorRefreshToken = 411,
}

export enum AudioSpeed {
  VerySlow = 'verySlow',
  Slow = 'slow',
  Regular = 'regular',
  Fast = 'fast',
}

export enum ChatRole {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
  AssistantHelper = 'assistantHelper',
}

export enum LLMModel {
  gpt3_5 = 'gpt-3.5-turbo-0125',
  gpt4 = 'gpt-4-turbo',
  geminipro = 'gemini-1.0-pro-latest',
  geminipro_1_5 = 'gemini-1.5-pro-latest',
  llama3_8b = 'llama3-8b-8192',
  llama3_70b = 'llama3-70b-8192',
  claudeHaiku = 'claude-3-haiku-20240307',
  claudeOpus = 'claude-3-opus-20240229',
}

export enum TextEngines {
  Plantext = 'plantext',
  MarkdownMultiMessages = 'markdownMultiMessages',
  MarkdownSSML = 'markdownSSML',
}
