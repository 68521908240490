import { User } from '../../classes';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

export { UserActions, UserSelectors };

export interface UserState {
  readonly user: User;
  readonly isRehydrated: boolean;
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly error: string;
}
