import { Injectable, HostListener } from '@angular/core';
import { Observable, distinctUntilChanged, fromEvent, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  public eventInstall;
  public isMobileAgent: boolean;
  public hasReponsiveView: boolean;
  public hasMobileView: boolean;


  private readonly mobileWidthThreshold = 768; // Adjust the threshold as needed
  private readonly responsibleWidthThreshold = 994;
  private screenWidth$: Observable<number>;
  public screenHeight$: Observable<number>;
  private isMobile$: Observable<boolean>;


  constructor() {

    this.hasReponsiveView = window.innerWidth <= this.responsibleWidthThreshold;

    this.checkMobileAgent();

    this.screenWidth$ = fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth),
      distinctUntilChanged()
    );

    this.screenHeight$ = fromEvent(window, 'resize').pipe(
      map(() => window.innerHeight),
      distinctUntilChanged()
    );

    this.isMobile$ = this.screenWidth$.pipe(
      map((width: number) => width <= this.responsibleWidthThreshold), // Add type assertion to 'width'
      distinctUntilChanged()
    );

    this.isMobile$.subscribe((isResposible) => {
      console.log('isMobile', isResposible);
      this.hasReponsiveView = isResposible;
    });
  }

  getScreenWidth(): Observable<number> {
    return this.screenWidth$;
  }



  isMobile(): Observable<boolean> {
    return this.isMobile$;
  }


  public installByUser() {
    console.log('Debería hacer la instalación por el usuario');
    if (this.eventInstall) {
      this.eventInstall.prompt();
      console.log('Debio mandar el promt');
    }
  }

  private checkMobileAgent(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobileAgent = true;
    } else {
      // false for not mobile device
      this.isMobileAgent = false;
    }
  }

}
