import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  version: '0.1.0',
  envName: 'QA',
  production: false, // cambiar a true para enviar notificaciones
  backUrl: 'https://app-kt3skyrvzq-uc.a.run.app',
  backApisUrl: 'https://apis-kt3skyrvzq-uc.a.run.app',

  // base de datos en dllanguages.
  firebase: {
    apiKey: 'AIzaSyBahj2VQDGPnpjIKv38YEe8FAyn7bcaloU',
    authDomain: 'appingles-qa.firebaseapp.com',
    projectId: 'appingles-qa',
    storageBucket: 'appingles-qa.appspot.com',
    messagingSenderId: '484991861457',
    appId: '1:484991861457:web:bfbc6360b6e81c974f7bf0',
  },
  logLevel: NgxLoggerLevel.TRACE,
  // googleAnalytics: "G-DNL54VQM58",
  gtm: 'GTM-N9N3C37',
};
