import { EntityState } from '@ngrx/entity';
import { Topic } from '../../classes';

import * as TopicsActions from './topics.actions';
import * as TopicsSelectors from './topics.selectors';

export { TopicsActions, TopicsSelectors };

export interface TopicsState extends EntityState<Topic>{
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly error: string;
}
