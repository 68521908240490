import { EnglishPhrase } from '../../classes';
import * as PhraseActions from './phrases.actions';
import * as PhraseSelectors from './phrases.selectors';

export { PhraseActions, PhraseSelectors };



export interface PhrasesState {
  phrases: ReadonlyArray<EnglishPhrase>;
  readonly isLoaded: boolean;
  readonly isLoading: boolean;
  readonly error: string;
}
