import { EnglishWord } from '../../classes';
import * as WordActions from './words.actions';
import * as WordSelectors from './words.selectors';

export { WordActions, WordSelectors };

export interface WordsState {
  words: ReadonlyArray<EnglishWord>;
  readonly isLoaded: boolean;
  readonly isLoading: boolean;
  readonly isRehydrated: boolean;
  readonly error: string;
}
